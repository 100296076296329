<template>
    <div>
        <page-title>{{ title }}</page-title>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="companyId"
        ></company-route-tabs>
        <users-table
            ref="table"
            :company="company"
            :fixed-filters="{ 'company.id': company }"
            :employee-list="true"
            :selected-filters.sync="selectedFilters"
            :selected-items.sync="selectedItems"
        >
            <template v-slot:actions>
                <create-button
                    v-if="canCreateUsers"
                    :to="{
                        name: routeType.USER_CREATE,
                        query: { company: company },
                    }"
                    tabbed
                ></create-button>
            </template>
        </users-table>
        <portal to="footer">
            <export :company-id="companyId" class="mr-2"></export>
            <import
                :company="company"
                :company-select="companySelect"
                @reload="$refs.table.reload(true, true)"
            ></import>
        </portal>
    </div>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiCollection from '@/api/RestApiCollection'
import TitleMixinGenerator from '@/components/mixins/page-title/TitleMixinGenerator'
import CreateButton from '@/components/form/Actions/CreateButton'
import CompanyRouteTabs from '@/components/pages/companies/RouteTabs'
import UsersTable from '@/components/pages/users/Table'
import Export from '@/pages/companies/employees/Export'
import Import from '@/pages/users/data/Import'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        CreateButton,
        CompanyRouteTabs,
        UsersTable,
        Export,
        Import,
    },
    mixins: [
        TitleMixinGenerator(SelectApiClientType.COMPANY, 'companyId'),
        AuthMixin,
    ],
    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            routeType,
            selectedFilters: {},
            selectedItems: [],
        }
    },
    computed: {
        company() {
            return this.companyId
                ? RestApiCollection.get(RestApiType.COMPANIES).getItemIri(
                      this.companyId
                  )
                : this.companyFilter
        },
        companySelect() {
            return this.isSuperAdmin && !this.companyId
        },
    },
}
</script>
