<template>
    <div>
        <v-text-field
            v-model="fileName"
            append-icon="attach_file"
            :name="name"
            :label="labelText"
            :error-messages="errors.collect(name)"
            readonly
            @click="pickFile"
        ></v-text-field>
        <input
            ref="fileInput"
            type="file"
            class="d-none"
            :accept="acceptedFiles"
            @change="filePicked"
        />
    </div>
</template>
<script>
import BaseInputMixin from './InputMixins/BaseInputMixin'

export default {
    mixins: [BaseInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: File,
            default: null,
        },
        acceptedFiles: {
            type: String,
            default: '*/*',
        },
    },
    data() {
        return {
            fileName: '',
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.$refs.fileInput.value = null
                this.fileName = ''
            }
        },
    },
    methods: {
        pickFile() {
            this.$refs.fileInput.click()
        },
        filePicked(event) {
            const files = event.target.files

            if (!files[0]) {
                this.fileName = ''

                return
            }

            const fileReader = new FileReader()

            fileReader.readAsDataURL(files[0])
            fileReader.addEventListener('load', () => {
                this.fileName = files[0].name
                this.$emit('input', files[0])
            })
        },
        reset() {
            this.$refs.fileInput.value = null
            this.fileName = ''
            this.$emit('input', null)
        },
    },
}
</script>
