import FileUpload from '@/services/FileUpload'

export default function (companyId, fileObject) {
    return FileUpload(
        'file',
        `api/v1/companies/${companyId}/import-employees`,
        {},
        fileObject
    )
}
