import CloneDeep from 'lodash/cloneDeep'
import RestApiCollection from '@/api/RestApiCollection'
import Notification from '@/services/Notification/Notification'

export default {
    data() {
        return {
            confirmActivationModal: false,
            confirmDeactivationModal: false,
            processingActivation: false,
            itemSelectedForActivation: null,
            showActivateCheckboxes: true,
            notiLabel: 'labels.restaurant',
        }
    },
    methods: {
        confirmActivation(item) {
            this.itemSelectedForActivation = CloneDeep(item)
            this.confirmActivationModal = true
        },
        confirmDeactivation(item) {
            this.itemSelectedForActivation = CloneDeep(item)
            this.confirmDeactivationModal = true
        },
        activateEntity() {
            this.processingActivation = true
            RestApiCollection.get(this.api)
                .activate(this.itemSelectedForActivation.id)
                .then(() => {
                    Notification.entityActivated(
                        this.notiLabel,
                        this.itemSelectedForActivation.name
                    )
                })
                .finally(() => {
                    this.confirmActivationModal = false
                    this.processingActivation = false
                    this.reloadAfterActivationChanges()
                })
        },
        deactivateEntity() {
            this.processingActivation = true
            RestApiCollection.get(this.api)
                .deactivate(this.itemSelectedForActivation.id)
                .then(() => {
                    Notification.entityDeactivated(
                        this.notiLabel,
                        this.itemSelectedForActivation.name
                    )
                })
                .finally(() => {
                    this.confirmDeactivationModal = false
                    this.processingActivation = false
                    this.reloadAfterActivationChanges()
                })
        },
        resetActiveCheckboxes() {
            this.confirmDeactivationModal = false
            this.confirmActivationModal = false
            this.showActivateCheckboxes = false

            this.$nextTick(() => {
                this.showActivateCheckboxes = true
            })
        },
        reloadAfterActivationChanges() {
            this.$refs.iterator.updateItems(true).then(() => {
                this.resetActiveCheckboxes()
                this.showActivateCheckboxes = true
            })
        },
    },
}
