export default {
    computed: {
        headers() {
            const fields = [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'lastName',
                    text: this.$t('labels.name'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'email',
                    text: this.$t('labels.email'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'phone',
                    text: this.$t('labels.phone'),
                    sortable: true,
                    type: 'string',
                },
            ]

            if (this.isSuperAdmin && !this.company) {
                fields.push({
                    value: 'company.name',
                    text: this.$t('labels.company'),
                    sortable: true,
                })
                fields.push({
                    value: 'userRoles',
                    text: this.$t('labels.roles'),
                    sortable: false,
                })
            }

            fields.push({
                value: 'enabled',
                text: this.$t('labels.active'),
            })

            return fields
        },
    },
}
