import apiClient from '@/api/ApiClient'
import Notification from '@/services/Notification/Notification'

export default function (field, url, query, fileObject) {
    const formData = new FormData()
    formData.append(field, fileObject)

    return apiClient
        .post(url, formData, {
            headers: { Accept: '*', 'Content-Type': 'multipart/form-data' },
            params: query,
        })
        .catch((reason) => {
            if (
                !reason.response ||
                reason.response.status !== 400 ||
                !reason.response.data
            ) {
                Notification.requestError(reason)
            }

            return Promise.reject(reason)
        })
}
