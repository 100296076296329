<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            :fixed-filters="fixedFilters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="!employeeList ? listFilters : []"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    :row-select="!employeeList"
                    :row-action="true"
                    @onRowSelected="onRowSelected"
                >
                    <!-- name column -->
                    <template v-slot:[`item.lastName`]="{ item }">
                        <router-link :to="getEditLink(item)">
                            {{ item.lastName }} {{ item.firstName }}
                        </router-link>
                    </template>

                    <!-- role column -->
                    <template v-slot:[`item.userRoles`]="{ item }">
                        <template v-if="item.userRoles && item.userRoles[0]">
                            {{ $t('roles.' + item.userRoles[0].role) }} <br />
                            <span
                                v-if="item.userRoles[0].company"
                                class="lower"
                            >
                                {{ item.userRoles[0].company.name }}
                            </span>
                            <span
                                v-if="item.userRoles[0].restaurant"
                                class="lower"
                            >
                                {{ item.userRoles[0].restaurant.name }}
                            </span>
                        </template>
                    </template>

                    <!-- active -->
                    <template v-slot:[`item.enabled`]="{ item }">
                        <checkbox-input
                            v-if="showActivateCheckboxes"
                            :value="item.enabled"
                            class="mt-0"
                            @input="
                                item.enabled
                                    ? confirmDeactivation(item)
                                    : confirmActivation(item)
                            "
                        ></checkbox-input>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.email"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-if="!employeeList" v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    strong
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>

        <confirm-modal
            :value="confirmActivationModal"
            :text="
                $t('texts.confirm_user_activation', {
                    user: itemSelectedForActivation
                        ? `${itemSelectedForActivation.lastName} ${itemSelectedForActivation.firstName}`
                        : '',
                })
            "
            :processing="processingActivation"
            @onApprove="activateEntity"
            @onCancel="resetActiveCheckboxes"
        ></confirm-modal>
        <confirm-modal
            :value="confirmDeactivationModal"
            :text="
                $t('texts.confirm_user_deactivation', {
                    user: itemSelectedForActivation
                        ? `${itemSelectedForActivation.lastName} ${itemSelectedForActivation.firstName}`
                        : '',
                })
            "
            :processing="processingActivation"
            @onApprove="deactivateEntity"
            @onCancel="resetActiveCheckboxes"
        ></confirm-modal>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import ActivateMixin from '@/components/mixins/ActivateMixin'
import SelectApiClientType from '@/api/SelectApiClientType'
import Headers from '@/pages/users/mixins/Headers'
import ListFilters from '@/pages/users/mixins/ListFilters'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    components: { ConfirmModal, CheckboxInput },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        ActivateMixin,
        AuthMixin,
        Headers,
        ListFilters,
    ],
    props: {
        company: {
            type: String,
            default: null,
        },
        fixedFilters: {
            type: Object,
            default: () => {
                return {}
            },
        },
        employeeList: {
            type: Boolean,
        },
    },
    data() {
        return {
            api: RestApiType.USERS,
            selectApiClientType: SelectApiClientType,
            notiLabel: 'labels.user',
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.users')
        },
    },
    watch: {
        itemSelectedForActivation: {
            handler() {
                this.$set(
                    this.itemSelectedForActivation,
                    'name',
                    `${this.itemSelectedForActivation.lastName} ${this.itemSelectedForActivation.firstName}`
                )
            },
        },
    },
    methods: {
        reload(update = false, onlyReload = false) {
            this.$refs.iterator.updateItems(update, onlyReload)
        },
        getEditLink(item) {
            return {
                name: routeType.USER_EDIT,
                params: { userId: item.id },
                query: { tab: 0 },
            }
        },
    },
}
</script>
