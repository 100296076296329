import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'company.id',
                    label: 'labels.employees_of_the_company',
                    inputType: 'select',
                    entity: SelectApiClientType.COMPANY,
                    useIdField: true,
                    quick: true,
                },
            ]
        },
    },
}
