<template lang="pug">
    v-btn(
        :loading="busy"
        color="primary"
        @click="exportCompanyEmployees")
        v-icon(left) cloud_download
        | {{ $t('actions.export') }}
</template>

<script>
import FileDownload from '@/services/FileDownload'

export default {
    props: {
        companyId: {
            type: [String, Number],
        },
    },
    data() {
        return {
            busy: false,
        }
    },
    methods: {
        exportCompanyEmployees() {
            this.busy = true
            return FileDownload(
                `/api/v1/companies/${this.companyId}/export-employees`,
                {}
            ).finally(() => {
                this.busy = false
            })
        },
    },
}
</script>
