<template>
    <div style="display: inline-block">
        <v-btn
            :disabled="!companySelect && !company"
            color="primary"
            class="white--text"
            @click="open"
        >
            <v-icon left>cloud_upload</v-icon>
            {{ $t('actions.import') }}
        </v-btn>
        <modal-dialog
            v-model="modal"
            :title="$t('users.import.title')"
            :ok-disabled="!selectedCompany || !file"
            :show-overlay="busy"
            cancel-button
            @ok="importCompanyEmployees"
        >
            <v-form>
                <entity-select-input
                    v-if="companySelect"
                    v-model="selectedCompany"
                    label="labels.company"
                    name="company"
                    :entity="selectApiClientType.COMPANY"
                    autocomplete
                ></entity-select-input>
                <file-input
                    v-model="file"
                    name="file"
                    accepted-files="text/csv"
                    label="labels.csv_file"
                ></file-input>
            </v-form>
        </modal-dialog>
        <modal-dialog
            v-model="errorModal"
            :title="$t('users.import.error_title')"
            @ok="errorModal = false"
        >
            <table class="table">
                <tr>
                    <th>line</th>
                    <th>field</th>
                    <th>error</th>
                </tr>
                <tr v-for="(error, index) in importErrors" :key="index">
                    <td>{{ error.line }}</td>
                    <td>{{ error.field }}</td>
                    <td>{{ error.message }}</td>
                </tr>
            </table>
        </modal-dialog>
    </div>
</template>

<script>
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import FileInput from '@/components/form/Inputs/FileInput'
import ImportUsers from '@/services/Users/ImportUsers'
import Notification from '@/services/Notification/Notification'
import ModalDialog from '@/components/dialog/ModalDialog'
import IdFromIri from '@/services/IdFromIri'

export default {
    components: {
        EntitySelectInput,
        FileInput,
        ModalDialog,
    },
    props: {
        company: {
            type: String,
            default: null,
        },
        companySelect: {
            type: Boolean,
        },
    },
    data() {
        return {
            selectedCompany: null,
            busy: false,
            modal: false,
            errorModal: false,
            selectApiClientType: SelectApiClientType,
            file: null,
            importErrors: [],
        }
    },
    methods: {
        open() {
            this.selectedCompany = this.company
            this.busy = false
            this.modal = true
            this.file = null
            this.importErrors = []
            this.errorModal = false
        },
        importCompanyEmployees() {
            this.busy = true
            const companyId = IdFromIri.getId(this.selectedCompany) || null
            ImportUsers(companyId, this.file)
                .then(() => {
                    Notification.importSuccessful('users.import.title')
                })
                .catch((error) => {
                    if (error.response.status === 400 && error.response.data) {
                        this.collectImportErrors(error.response.data)
                        this.errorModal = true
                    }
                })
                .finally(() => {
                    this.busy = false
                    this.modal = false
                    this.file = null
                    this.$emit('reload')
                })
        },
        collectImportErrors(errors) {
            for (const fileName of Object.keys(errors.errors)) {
                for (const line of Object.keys(errors.errors[fileName])) {
                    for (const field of Object.keys(
                        errors.errors[fileName][line]
                    )) {
                        const fieldErrors = errors.errors[fileName][line][field]

                        fieldErrors.forEach((error) => {
                            this.importErrors.push({
                                line,
                                field,
                                message: this.buildErrorMessage(
                                    error.message,
                                    error.parameters
                                ),
                            })
                        })
                    }
                }
            }
        },
        buildErrorMessage(message, parameters) {
            for (const parameter of Object.keys(parameters)) {
                message = message.split(parameter).join(parameters[parameter])
            }

            return message
        },
    },
}
</script>
